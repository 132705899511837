.react-datepicker__header {
    background-color: #f0f0f0;
}

.react-datepicker__day {
    width: 36px;
    height: 36px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin: 2px;
    cursor: pointer;
    border-radius: 50%;
}

.react-datepicker__day:hover {
    background-color: #e6e6e6;
}

.react-datepicker__day--selected {
    background-color: #007bff;
    color: white;
}

.react-datepicker__day--keyboard-selected {
    background-color: #007bff;
    color: white;
}

.react-datepicker__current-month {
    font-weight: bold;
}

.react-datepicker__day-names,
.react-datepicker__week {
    display: flex;
    justify-content: space-between;
}

.react-datepicker__navigation--previous,
.react-datepicker__navigation--next {
    display: block;
    border: 0.45rem solid transparent;
}

.react-datepicker__navigation--previous {
    border-right-color: #ccc;
    left: 10px;
}

.react-datepicker__navigation--next {
    border-left-color: #ccc;
    right: 10px;
}

.react-datepicker {
    font-family: Arial, Helvetica, sans-serif;
    border: 1px solid #ccc;
    border-radius: 8px;
    overflow: hidden;
    width: 100%;
    max-width: 300px;
}

.react-datepicker__day--disabled {
    color: #ccc;
    pointer-events: none;
}

@media (max-width: 768px) {
    .react-datepicker {
        width: 100%;
        max-width: 100%;
    }

    .react-datepicker__day {
        width: 32px;
        height: 32px;
    }
}

@media (max-width: 480px) {
    .react-datepicker {
        width: 100%;
        max-width: 100%;
    }

    .react-datepicker__day {
        width: 28px;
        height: 28px;
    }
}